export default {
    nav: {
        home: 'Home',
        login: 'Login',
        reset_password: 'Reset Password',
        our_fleet: 'Our Fleet',
        my_profile: 'My Profile',
        my_bookings: 'My Bookings',
        logout: 'Logout',
    },
    home: {
        title: 'SCH Car Booking System',
        subtitle: 'Check out our fleet and book a car according to your needs',
        pick_up_date: 'Pick-up date & time',
        return_date: 'Return date & time',
        pick_up_required: 'Pick-up date & time are required',
        return_required: 'Return date & time are required'
    },
    our_fleet: {
        brands: 'Brands',
        seating_capacity: 'Seating Capacity',
        book_now: 'Book Now',
        error_retrieve_vehicles: 'Failed to retrieve vehicles',
        error_retrieve_brands: 'Failed to retrieve brands',
        error_retrieve_seating_capacity_options: 'Failed to retrieve seating capacity options',
    },
    vehicle: {
        booking_details: 'Booking Details',
        pick_up_location: 'Pick-Up Location',
        pick_up_date: 'Pick-Up Date & Time',
        drop_off_location: 'Drop-Off Location',
        drop_off_date: 'Drop-Off Date',
        seating_capacity: 'Seating Capacity',
        transmission: 'Transmission',
        fuel_type: 'Fuel Type',
        failed_create_booking: 'Failed to create booking',
        obo: 'O.B.O',
    },
    bookings: {
        id: 'ID',
        date_time_from: 'Date & Time From',
        date_time_to: 'Date & Time To',
        license_plate: 'License Plate',
        model: 'Model',
        bookings: 'Bookings',
        booking_by: 'Booking by',
        add_booking: 'Add Booking',
        search_bookings: 'Search bookings',
        booking_details: 'Booking Details',
        book: 'Book',
        audits: 'Audits',
        user: 'User',
        event: 'Event',
        detail: 'Detail',
        new_values: 'New Values',
        old_values: 'Old Values',
        vehicle_id: 'Vehicle ID',
        status: 'Status',
        from: 'Pick up location',
        to: 'Drop off location',
        user_id:'User ID',
        reference: 'Reference',
        date_and_time: 'Date & Time',
        pick_up_date: 'Pick-Up Date & Time',
        drop_off_date: 'Drop-Off Date & Time',
        pick_up_location: 'Pick-Up Location',
        drop_off_location: 'Drop-Off Location',
        obo: 'O.B.O',
        view_booking: 'View Booking',
        update_booking: 'Update Booking',
        delete_booking: 'Delete Booking',
        cancel_booking: 'Cancel Booking',
        start_booking: 'Start Booking',
        stop_booking: 'Stop Booking',
        kilometers: 'Kilometers',
        mileage_must_be_greater_than: 'Mileage to stop booking must be greater than {x} KM',
        please_enter_kilometers: 'Please enter the car\'s kilometers value below',
        prompt_delete: 'Are you sure you want to delete this booking?',
        prompt_cancel: 'Are you sure you want to cancel this booking?',
        error_delete: 'Failed to delete booking',
        error_cancel: 'Failed to cancel booking',
        error_retrieve: 'Failed to retrieve bookings',
        error_create: 'Failed to create booking',
        error_update: 'Failed to update booking',
        success_delete: 'Booking has been deleted',
        success_create: 'Booking has been created',
        success_update: 'Booking has been updated',
        error_retrieve_audits: 'Failed to retrieve audits',
    },
    vehicles: {
        name: 'Name',
        license_plate: 'License Plate',
        brand: 'Brand',
        model: 'Model',
        status: 'Status',
        transmission: 'Transmission',
        fuel_type: 'Fuel Type',
        seats: 'Seats',
        id: 'ID',
        reason: 'Reason',
        date_and_time: 'Date & Time',
        detail: 'Detail',
        event: 'Event',
        user: 'User',
        vehicle_details: 'Vehicle Details',
        vehicles_brands: 'Vehicle\'s Brands',
        obo: 'O.B.O',
        status_change: 'Status Change',
        vehicle: 'Vehicle',
        vehicles: 'Vehicles',
        add_brand: 'Add Brand',
        edit_brand: 'Edit Brand',
        issue_history: 'Issue History',
        issue: 'Issue',
        issues: 'Issues',
        audits: 'Audits',
        add_vehicle: 'Add Vehicle',
        add_vehicle_brand: 'Add Vehicle Brand',
        search_vehicles: 'Search vehicles',
        delete_vehicle: 'Delete Vehicle',
        delete_brand: 'Delete Brand',
        prompt_delete: 'Are you sure you want to delete this vehicle?',
        prompt_delete_brand: 'Are you sure you want to delete this brand?',
        error_delete: 'Failed to delete vehicle',
        error_retrieve: 'Failed to retrieve vehicles',
        error_create: 'Failed to create vehicle',
        error_update: 'Failed to update vehicle',
        success_delete: 'Vehicle has been deleted',
        success_create: 'Vehicle has been created',
        success_update: 'Vehicle has been updated',
        status_change_description: 'Please choose from below why the status changed',
    },
    profile: {
        personal_information: 'Personal Information',
        change_password: 'Change Password',
        name: 'Name',
        email_address: 'Email Address',
        password: 'Password',
        error_retrieve: 'Failed to retrieve profile',
        successfully_updated: 'Profile has been successfully updated!',
        obo: 'O.B.O',
    },
    prompts: {
        are_you_sure: 'are you sure?',
        do_you_want_to_delete_this_x: 'do you want to delete this {x}?',
    },
    validation: {
        x_is_required: '{x} is required',
        x_are_required: '{x} are required',
        please_enter_x: 'please enter {x}',
        please_enter_longer_x: 'please enter a longer {x}',
        please_enter_shorter_x: 'please enter a shorter {x}',
        please_enter_valid_x: 'please enter a valid {x}',
        please_choose_x: 'please choose {x}',
        please_enter_number: 'please enter a number',
        please_enter_number_between_x: 'please enter a number between {x} and {y}',
        please_enter_url: 'please enter a URL',
    },
    auth: {
        unauthorized: 'Unauthorized',
        unauthenticated: 'Unauthenticated',
        forgot_password_q: 'Forgot password?',
        forgot_password: 'Forgot password',
        forgot_password_text: 'Enter your email and we\'ll send you a link to reset your password.',
        password_has_been_updated: 'Your password has been updated!',
        email: 'Email',
        password: 'Password',
        password_is_required: 'Password is required',
        email_is_required: 'Email is required',
        reset_password: 'Reset Password',
        enter_valid_email: 'Enter a valid email',
        email_sent: 'Email successfully sent',
        return_to_login: 'Return to Login',
        enter_your_new_password: 'Enter your new password',
        retype_password: 'Re-type password',
        failed_login: 'Login Failed',
        failed_reset: 'Reset Failed',
        identity_verified: 'Your identity has been verified!',
        set_new_password: 'Set your new password!',
    },
    localities: {
        select_locality: 'Select locality',
        error_retrieve: 'Failed to retrieve localities',
    },
    cancel: 'Cancel',
    confirm: 'Confirm',
    delete: 'Delete',
    remove: 'Remove',
    start: 'Start',
    stop: 'Stop',
    finish: 'Finish',
    add: 'Add',
    update: 'Update',
    save: 'Save',
    print: 'Print',
    send: 'Send',
    search: 'Search',
    select: 'Select',
    page_not_found: 'Page not found',
    x_per_page: '{x} per page',
    error: 'Error',
    yes: 'Yes',
    no: 'No',
    accepted: 'Accepted',
    not_accepted: 'Not Accepted',
    warning: 'Warning',
    actions: 'Actions',
    back: 'Back',
    submit: 'Submit',
    close: 'Close',
}
