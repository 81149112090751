import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import Login from "../views/Login";
import NotFound from "../views/NotFound";
import {i18n} from '../locale/i18n';
import Reset from "@/views/Reset";
import Home from "@/views/Home";
import OurFleet from "@/views/OurFleet";
import MyBookings from "@/views/MyBookings";
import MyProfile from "@/views/MyProfile";
import VehicleInner from "@/views/VehicleInner";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/our-fleet',
        name: 'our-fleet',
        component: OurFleet
    },
    {
        path: '/vehicles/:vehicle_id',
        name: 'vehicle-inner',
        component: VehicleInner,
        meta: {
            auth: true,
        }
    },
    {
        path: '/bookings',
        name: 'bookings',
        component: MyBookings,
        meta: {
            auth: true,
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: MyProfile,
        meta: {
            auth: true,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/password/reset',
        name: 'reset',
        component: Reset
    },
    {
        path: '*',
        name: 'notfound',
        component: NotFound
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});


router.beforeEach(async (to, from, next) => {
    window.scrollTo(0, 0);

    if (!to.meta)
        next();

    // check auth
    if (to.meta.auth) {
        const routeAuth = to.meta.auth;

        if (routeAuth === true) {
            // user must be logged in
            if (!store.getters.token) {
                Vue.notify({
                    type: 'error',
                    text: i18n.t('auth.unauthenticated')
                });

                return next({name: 'login'});
            }
        } else if (routeAuth.toLowerCase() === 'guest') {
            // user must be logged out
            if (store.getters.token)
                return next({path: '/'});
        }
    }

    // check permission
    if (to.meta.any_permissions) {
        // check that the user has some of the required permissions

        if (!store.getters.hasAnyPermission(to.meta.any_permissions)) {
            Vue.notify({
                type: 'error',
                text: i18n.t('auth.unauthorized')
            });

            return next({path: '/'});
        }
    }

    // check permission
    if (to.meta.all_permissions) {
        // check that the user has all the required permissions

        let permissions = to.meta.all_permissions;
        if (!store.getters.hasAllPermissions(permissions)) {
            Vue.notify({
                type: 'error',
                text: i18n.t('auth.unauthorized')
            });

            return next({path: '/'});
        }
    }

    store.commit('CLOSE_MENU_EXPANDED');
    return next();
});

export default router;
