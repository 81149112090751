<template>
    <div>
        <AuthLayout v-if="$route.name ==='login' || $route.name ==='reset'">
            <router-view/>
        </AuthLayout>
        <AppLayout v-else>
            <router-view/>
        </AppLayout>
        <notifications position="bottom left" classes="notification-card"/>
    </div>
</template>

<script>
    import AppLayout from "./layouts/AppLayout";
    import AuthLayout from "@/layouts/AuthLayout";

    export default {
        name: "App",
        components: {AuthLayout, AppLayout}
    }
</script>
