<template>
    <div class="stop-booking-modal">
        <ModalContainer :title="title" identifier="stop-booking-modal" :closable="true">
            <div class="sure-container">
                <p>{{message}}</p>

                <slot name="body">
                    <FormInputText v-model="$v.kilometers.$model" identifier="kilometers" :label="$t('bookings.kilometers')"
                                   :placeholder="$t('bookings.kilometers')" :disabled="is_saving"
                                   :has-error="$v.kilometers.$error || mileage_error">
                        <template v-slot:errors>
                            <p v-if="!$v.kilometers.required" class="errors">
                                {{ $t('validation.x_is_required', {x: $t('bookings.kilometers')}) }}
                            </p>
                            <p v-else-if="$v.kilometers.required && mileage_error" class="errors">
                                {{ $t('bookings.mileage_must_be_greater_than', {x: parseInt(booking.attributes.trip_start_km).toLocaleString('en')}) }}
                            </p>
                        </template>
                    </FormInputText>
                </slot>

                <div class="buttons">
                    <Button :className="`${cancelClass} wider`" :onclick="()=>close(false)">
                        {{cancelText}}
                    </Button>
                    <Button :className="`${confirmClass} wider`" :onclick="()=>submit()">
                        {{confirmText}}
                    </Button>
                </div>
            </div>

            <div class="bottom-text" v-if="subtext">
                <p>{{subtext}}</p>
            </div>
        </ModalContainer>
    </div>
</template>

<script>
    import Button from "../Button";
    import ModalContainer from "./ModalContainer";
    import FormInputText from "@/components/form/FormInputText";
    import {required, minValue} from 'vuelidate/lib/validators'

    export default {
        name: 'StopBookingModal',
        components: {FormInputText, ModalContainer, Button},
        props: {
            title: {
                type: String,
                required: false,
                default: 'Confirm',
            },
            message: {
                type: String,
                required: false,
                default: 'Are you sure?',
            },
            subtext: {
                type: String,
                required: false,
                default: null,
            },
            confirmText: {
                type: String,
                required: false,
                default: 'Yes',
            },
            cancelText: {
                type: String,
                required: false,
                default: 'No'
            },
            confirmClass: {
                type: String,
                required: false,
                default: '--negative'
            },
            cancelClass: {
                type: String,
                required: false,
                default: '--primary',
            },
            booking: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                kilometers: null,
                mileage_error: false,
                is_saving: false
            }
        },
        validations: {
            kilometers: {
                required
            }
        },
        methods: {
            submit() {
                this.$v.kilometers.$touch();
                if(this.$v.kilometers.$anyError) return;

                if(parseInt(this.kilometers) <= parseInt(this.booking.attributes.trip_start_km)) {
                    this.mileage_error = true;
                    return;
                } else {
                    this.mileage_error = false;
                }

                let payload = {
                    trip_finish_km: this.kilometers,
                    status: 'completed'
                }

                this.is_saving = true;

                this.$axios.patch(`bookings/${this.booking.id}`, payload)
                    .then(({data}) => {
                        this.is_saving = false;
                        this.close(true);
                    })
                    .catch(e => {
                        this.is_saving = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('booking.error_cancel')),
                            type: 'error',
                        });
                    });

                this.close(true);
            },
            close(status) {
                this.$modal.hide('stop-booking-modal', status);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .stop-booking-modal {
        .sure-container {
            @apply flex flex-col items-center mt-6 max-w-xs mx-auto;

            & > p {
                @apply text-black text-sm mb-6;
            }

            .buttons {
                @apply flex flex-row justify-center mt-2;

                button {
                    @apply mx-4 mt-0;
                }
            }
        }

        .bottom-text {
            p {
                @apply text-xs text-grey-dark italic text-center mt-8 leading-loose;
            }
        }
    }
</style>
