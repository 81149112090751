<template>
    <loading-screen v-if="is_loading_brands || is_loading_seating_capacity_options"/>
    <div v-else class="inner-page-container">
        <div class="searchbar-container">
            <div class="date-picker-container">
                <font-awesome-icon :icon="['fal','calendar']"/>
                <VueCtkDateTimePicker color="#171628" v-model="pick_up_date" :min-date="todayDate" :minute-interval="60"
                                      :label="$t('home.pick_up_date')" id="pick-up-date"/>
            </div>
            <div class="date-picker-container">
                <font-awesome-icon :icon="['fal','calendar']"/>
                <VueCtkDateTimePicker color="#171628" v-model="return_date" :min-date="minReturnDate"
                                      :max-date="maxReturnDate" :minute-interval="60" :label="$t('home.return_date')"
                                      id="return-date"/>
            </div>
            <button class="button --primary" @click="search">Search</button>
        </div>

        <div class="content">
            <div class="filter-section">
                <div class="filter-container" v-if="brands.length">
                    <div class="filter-heading">
                        <p>{{ $t('our_fleet.brands') }}</p>
                        <font-awesome-icon v-if="!brands_open" :icon="['far','chevron-down']"
                                           @click="toggleBrandsOpen"/>
                        <font-awesome-icon v-else :icon="['far','chevron-up']" @click="toggleBrandsOpen"/>
                    </div>

                    <div class="filter-content" v-if="brands_open">
                        <div class="brand" v-for="brand in brands">
                            <p-check class="checkbox p-icon p-curve" color="primary" v-model="brand.checked"
                                     @change="onFilterUpdated('brand', brand, brand.checked)">
                                <font-awesome-icon slot="extra" :icon="['far', 'check']" class="icon"/>
                            </p-check>
                            <p>{{ brand.attributes.name }}</p>
                        </div>
                    </div>
                </div>
                <div class="filter-container" v-if="seating_capacity_options.length">
                    <div class="filter-heading">
                        <p>{{ $t('our_fleet.seating_capacity') }}</p>
                        <font-awesome-icon v-if="!seating_capacity_open" :icon="['far','chevron-down']"
                                           @click="toggleSeatingCapacityOpen"/>
                        <font-awesome-icon v-else :icon="['far','chevron-up']" @click="toggleSeatingCapacityOpen"/>
                    </div>

                    <div class="filter-content" v-if="seating_capacity_open">
                        <div class="brand" v-for="seating_capacity in seating_capacity_options">
                            <p-check class="checkbox p-icon p-curve" type="radio" color="primary"
                                     v-model="seating_capacity.checked"
                                     @change="onFilterUpdated('seating', seating_capacity, seating_capacity.checked)">
                                <font-awesome-icon slot="extra" :icon="['far', 'check']" class="icon"/>
                            </p-check>
                            <p v-if="seating_capacity.value === 5">{{ seating_capacity.value }}+</p>
                            <p v-else>{{ seating_capacity.value }}</p>

                        </div>
                    </div>
                </div>
            </div>

            <div class="vehicles-section">
                <LoadingScreen v-if="is_loading_vehicles"/>
                <div v-else-if="!is_loading_vehicles && vehicles.length" class="vehicles-container">
                    <div class="vehicle-wrapper" v-for="vehicle in vehicles">
                        <div class="vehicle-container">
                            <img
                                v-if="vehicle.relationships.documents && vehicle.relationships.documents.data[0] && vehicle.relationships.documents.data[0].attributes"
                                :src="vehicle.relationships.documents.data[0].attributes.image_path" alt="">
                            <div class="row">
                                <p class="vehicle-name">{{ vehicle.relationships.brand.data.attributes.name }}
                                    {{ vehicle.attributes.model }}</p>
                                <p class="vehicle-license-plate">{{ vehicle.attributes.license_plate }}</p>
                            </div>

                            <div class="row">
                                <div class="feature">
                                    <img class="feature-icon"
                                         src="../../src/assets/icons/person-seat-reclined-light.svg"/>
                                    <p class="feature-text">{{ vehicle.attributes.seats }}</p>
                                </div>

                                <div class="feature">
                                    <img class="feature-icon" src="../../src/assets/icons/gear-shift-light.svg"/>
                                    <p class="feature-text">{{ vehicle.attributes.transmission }}</p>
                                </div>

                                <div class="feature">
                                    <font-awesome-icon class="feature-icon" :icon="['fal','gas-pump']"/>
                                    <p class="feature-text">{{ vehicle.attributes.fuel_type }}</p>
                                </div>
                            </div>

                            <button class="button --primary"
                                    :disabled="vehicle.attributes.status === 'unavailable' || !$store.getters.user"
                                    @click="$router.push({name: 'vehicle-inner', params: {vehicle_id: vehicle.id}})">
                                {{ $t('our_fleet.book_now') }}
                            </button>
                        </div>
                    </div>
                </div>
                <p v-else="vehicles.length === 0" class="no-vehicles-text">No vehicles available</p>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import FormInputCheck from "@/components/form/FormInputCheck";
import LoadingScreen from "@/components/LoadingScreen";

export default {
    name: "OurFleet",
    components: {LoadingScreen, FormInputCheck},
    data() {
        return {
            serverParams: {},
            pick_up_date: null,
            return_date: null,
            vehicles: [],
            brands: [],
            brands_open: false,
            brand_selected: null,
            seating_capacity_options: [],
            seating_capacity_open: false,
            seating_capacity_selected: null,
            is_loading_vehicles: false,
            is_loading_brands: false,
            is_loading_seating_capacity_options: false,
        }
    },
    computed: {
        todayDate() {
            let date = new Date();
            return moment(date).format('YYYY-MM-DD HH:mm');
        },
        minReturnDate() {
            return moment(this.pick_up_date).add(1, 'hours').format('YYYY-MM-DD HH:mm');
        },
        maxReturnDate() {
            return moment(this.pick_up_date).endOf('day').format('YYYY-MM-DD HH:mm');
        }
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search() {
            if (this.pick_up_date && this.return_date) {
                this.updateParams({
                    from: moment(this.pick_up_date).format('YYYY-MM-DD HH:mm:ss'),
                    to: moment(this.return_date).format('YYYY-MM-DD HH:mm:ss')
                });
                this.getVehicles();
            }
        },
        onFilterUpdated(type, option, is_checked) {
            const filters = [];

            if (type === 'brand') {
                this.brands.forEach(b => {
                    if (b.id !== option.id) {
                        b.checked = false;
                    } else if (b.id === option.id && is_checked) {
                        this.brand_selected = b.id;
                    } else if (!is_checked)
                        this.brand_selected = null;
                })
            } else if (type === 'seating') {
                this.seating_capacity_options.forEach(s => {
                    if (s !== option) {
                        s.checked = false;
                    } else if (s === option && is_checked) {
                        this.seating_capacity_selected = s;
                    } else if (!is_checked)
                        this.seating_capacity_selected = null;
                })
            }

            if (this.brand_selected)
                filters.push(
                    {
                        filter_by: 'brand_id',
                        filter_operator: '=',
                        filter_value: this.brand_selected
                    },
                );

            if (this.seating_capacity_selected) {
                if (this.seating_capacity_selected.value < 5) {
                    filters.push(
                        {
                            filter_by: 'seats',
                            filter_operator: '=',
                            filter_value: this.seating_capacity_selected
                        },
                    );
                }

                else {
                    filters.push(
                        {
                            filter_by: 'seats',
                            filter_operator: '>=',
                            filter_value: this.seating_capacity_selected
                        },
                    );
                }
            }


            if (filters.length)
                this.updateParams({
                    filters: filters
                });
            else
                this.removeParam('filters');

            this.getVehicles();
        },
        getVehicles() {
            this.is_loading_vehicles = true;

            this.$axios.get('vehicles/get-availabilities', {params: this.serverParams})
                .then(({data}) => {
                    this.vehicles = data.data;
                    this.is_loading_vehicles = false;
                })
                .catch(e => {
                    this.is_loading_vehicles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('our_fleet.error_retrieve_vehicles')),
                        type: 'error',
                    });
                });
        },
        async getBrands() {
            this.is_loading_brands = true;

            await this.$axios.get('brands/list')
                .then(({data}) => {
                    this.brands = data.data;
                    this.is_loading_brands = false;
                })
                .catch(e => {
                    this.is_loading_brands = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('our_fleet.error_retrieve_brands')),
                        type: 'error',
                    });
                });
        },
        toggleBrandsOpen() {
            this.brands_open = !this.brands_open;
        },
        async getSeatingCapacityOptions() {
            this.is_loading_seating_capacity_options = true;

            await this.$axios.get('vehicles/list-seats')
                .then(({data}) => {
                    this.seating_capacity_options = data;
                    this.is_loading_seating_capacity_options = false;
                })
                .catch(e => {
                    this.is_loading_seating_capacity_options = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('our_fleet.error_retrieve_seating_capacity_options')),
                        type: 'error',
                    });
                });
        },
        toggleSeatingCapacityOpen() {
            this.seating_capacity_open = !this.seating_capacity_open;
        },
        populate() {
            if (this.$route.query.pick_up_date)
                this.pick_up_date = this.$route.query.pick_up_date;

            if (this.$route.query.return_date)
                this.return_date = this.$route.query.return_date;
        }
    },
    async mounted() {
        if (this.$route.query.pick_up_date && this.$route.query.return_date) {
            this.populate();
            this.search();
        } else
            this.getVehicles();

        await this.getBrands();
        await this.getSeatingCapacityOptions();
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.our_fleet')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.inner-page-container {
    @apply bg-grey flex flex-col px-4 pb-12;

    @screen md {
        @apply px-6;
    }

    .searchbar-container {
        @apply flex flex-col bg-white p-6 rounded-2xl mx-auto w-full max-w-sm my-12;
        box-shadow: 0px 5px 25px #00000029;

        @screen lg {
            @apply max-w-4xl w-full flex-row justify-between;
        }

        .date-picker-container {
            @apply mb-4 flex flex-row;

            @screen lg {
                @apply my-auto mx-auto;
            }

            &:first-of-type {
                @screen lg {
                    @apply pr-4 mr-8 border-r-2 border-primary;
                }
            }

            svg {
                @apply my-auto text-xl mr-4;

                @screen lg {
                    @apply text-2xl;
                }
            }
        }

        button {
            @apply w-full rounded-lg;

            @screen md {
                @apply w-auto;
            }
        }
    }

    .content {
        @apply flex flex-col w-full max-w-9xl mx-auto;

        @screen lg {
            @apply flex-row;
        }

        @screen 2xl {
            @apply px-6;
        }

        .filter-section {
            @apply bg-white rounded-2xl p-6 w-full max-w-sm w-full mx-auto mb-12;
            height: min-content;

            @screen lg {
                @apply w-1/4 mb-0 ml-0 mr-8;
            }

            @screen xl {
                @apply mr-10;
            }

            .filter-container {
                @apply mb-4;

                &:last-of-type {
                    @apply mb-0;
                }

                .filter-heading {
                    @apply flex flex-row p-6 rounded-lg bg-grey justify-between;

                    & > p {
                        @apply text-base font-semibold;
                    }

                    & > svg {
                        @apply my-auto cursor-pointer;
                    }
                }

                .filter-content {
                    @apply px-6 pt-6;

                    .brand {
                        @apply flex flex-row mb-4;

                        &:last-of-type {
                            @apply mb-0;
                        }

                        .checkbox {
                            @apply my-auto pr-2;
                        }

                        p {
                            @apply font-semibold text-sm;
                        }
                    }
                }
            }
        }

        .vehicles-section {
            @apply max-w-sm w-full mx-auto;

            @screen md {
                @apply mx-auto max-w-none;
            }

            @screen lg {
                @apply m-0 flex-1 max-w-none;
            }

            .vehicles-container {
                @apply flex flex-col;

                @screen md {
                    @apply flex-row flex-wrap;
                }

                @screen lg {
                    @apply -m-4;
                }

                .vehicle-wrapper {
                    @apply mb-12;

                    @screen md {
                        @apply p-4 mb-0 w-1/2;
                    }

                    @screen xl {
                        @apply w-1/3;
                    }

                    &:last-of-type {
                        @apply mb-0;
                    }

                    .vehicle-container {
                        @apply flex flex-col p-4 rounded-2xl bg-white;

                        & > img {
                            @apply mb-4 h-48;
                            object-fit: cover;
                        }

                        .row {
                            @apply flex flex-row justify-between items-center;

                            &:first-of-type {
                                @apply pb-4 mb-4 border-b-2 border-primary;
                            }

                            .vehicle-name {
                                @apply font-semibold text-base;

                                @screen lg {
                                    @apply text-xl;
                                }
                            }

                            .vehicle-license-plate {
                                @apply px-2 py-1 border border-grey-darkest rounded font-semibold text-xs;

                                @screen lg {
                                    @apply text-sm;
                                }
                            }

                            .feature {
                                @apply flex flex-row items-center;

                                .feature-text {
                                    @apply text-sm;

                                    @screen lg {
                                        @apply text-base;
                                    }
                                }

                                .feature-icon {
                                    @apply mr-2 text-lg;
                                }
                            }
                        }

                        button {
                            @apply mt-6 rounded-xl;
                        }
                    }
                }
            }

            .no-vehicles-text {
                @apply text-secondary font-semibold text-2xl mx-auto mt-8 text-center;

                @screen lg {
                    @apply text-left ml-4;
                }
            }
        }
    }
}
</style>