<template>
    <loading-screen v-if="is_loading_profile"/>
    <div v-else class="inner-page-container">
        <div class="content">
            <div class="heading-bar">
                <h1>{{ $t('nav.my_profile') }}</h1>
                <button class="button --primary --small --wide" @click="updateProfile" :class="{'spinner-white': is_saving}" :disabled="is_saving">Save</button>
            </div>

            <main>
                <Form class="form" :disabled="is_saving">
                    <SectionHeader :title="$t('profile.personal_information')"></SectionHeader>
                    <div class="form-body">
                        <FormGroupTwo>
                            <FormInputText v-model="$v.personal_information.name.$model" identifier="name" :label="$t('profile.name')"
                                           :placeholder="$t('profile.name')" :disabled="is_saving"
                                           :has-error="$v.personal_information.name.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.personal_information.name.required">
                                        {{ $t('validation.x_is_required', {x: $t('profile.name')}) }}
                                    </p>
                                </template>
                            </FormInputText>
                            <FormInputText v-model="$v.personal_information.email_address.$model" identifier="name" :label="$t('profile.email_address')"
                                           :placeholder="$t('profile.email_address')" :disabled="is_saving"
                                           :has-error="$v.personal_information.email_address.$error">
                                <template v-slot:errors>
                                    <p v-if="!$v.personal_information.email_address.required">
                                        {{ $t('validation.x_is_required', {x: $t('profile.email_address')}) }}
                                    </p>
                                </template>
                            </FormInputText>
                        </FormGroupTwo>
                    </div>
                </Form>

                <Form class="form" :disabled="is_saving">
                    <SectionHeader :title="$t('profile.change_password')"></SectionHeader>
                    <div class="form-body">
                        <FormGroupTwo>
                            <FormInputPassword v-model="password" identifier="password" :label="$t('profile.password')"
                                               :placeholder="$t('profile.password')" :disabled="is_saving" type="password" autocomplete="off">
                            </FormInputPassword>
                        </FormGroupTwo>
                    </div>
                </Form>
            </main>
        </div>
    </div>
</template>

<script>
import Form from "@/components/form/Form";
import SectionHeader from "@/components/SectionHeader";
import FormGroupTwo from "@/components/form/FormGroupTwo";
import FormInputText from "@/components/form/FormInputText";
import {required} from "vuelidate/lib/validators";
import FormInputPassword from "@/components/form/FormInputPassword";
import LoadingScreen from "@/components/LoadingScreen";
export default {
    name: "MyProfile",
    components: {LoadingScreen, FormInputPassword, FormInputText, FormGroupTwo, SectionHeader, Form},
    data() {
        return {
            personal_information: {
                name: null,
                email_address: null,
            },
            password: null,
            is_loading_profile: false,
            is_saving: false
        }
    },
    validations: {
        personal_information: {
            name: {required},
            email_address: {required},
        }
    },
    methods: {
        getProfile() {
            this.is_loading_profile = true;

            this.$axios.get('profile')
                .then(({data}) => {
                    this.personal_information.name = data.data.attributes.name;
                    this.personal_information.email_address = data.data.attributes.email;

                    this.is_loading_profile = false;
                })
                .catch(e => {
                    this.is_loading_profile = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('profile.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        updateProfile() {
            this.$v.personal_information.$touch();

            if(this.$v.personal_information.$anyError)
                return;

            this.is_saving = true;

            let payload = {
                name: this.personal_information.name,
                email: this.personal_information.email_address,
            };

            if(this.password)
                payload.password = this.password;

            this.$axios.patch('profile', payload)
                .then(({data}) => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('success'),
                        text: this.$t('profile.successfully_updated'),
                        type: 'success',
                    });
                })
                .catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('profile.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },
    mounted() {
        this.getProfile();
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.my_profile')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.inner-page-container {
    @apply bg-grey flex flex-col px-4;

    @screen md {
        @apply px-6;
    }

    .content {
        @apply flex flex-col w-full max-w-9xl mx-auto;

        @screen 2xl {
            @apply px-6;
        }

        .heading-bar {
            @apply flex flex-row my-12 items-center justify-between;

            & > h1 {
                @apply text-3xl font-semibold mr-8;

                @screen md {
                    @apply text-4xl;
                }

                @screen lg {
                    @apply text-5xl;
                }
            }
        }

        main {
            @apply flex flex-row flex-wrap;

            .form {
                @apply mb-8 w-full mx-auto;

                @screen xl {
                    width: calc(50% - 1rem);

                    &:nth-child(odd) {
                        @apply mr-4 ml-0;
                    }

                    &:nth-child(even) {
                        @apply ml-4 mr-0;
                    }
                }

                .form-body {
                    @apply px-8 pt-7 pb-2.5;

                }
            }
        }
    }
}
</style>