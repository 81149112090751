<template>
    <loading-screen v-if="is_loading_vehicle"/>
    <div v-else-if="!is_loading_vehicle && vehicle" class="inner-page-container">
        <div class="content">
            <div class="heading-bar">
                <h1>{{vehicle.relationships.brand.data.attributes.name}} {{vehicle.attributes.model}}</h1>
                <p class="vehicle-license-plate">{{vehicle.attributes.license_plate}}</p>
            </div>

            <div class="row">
                <div class="main-img">
                    <img v-if="vehicle.relationships.documents && vehicle.relationships.documents.data[0] && vehicle.relationships.documents.data[0].attributes" :src="vehicle.relationships.documents.data[0].attributes.image_path" alt="">
                </div>

                <div class="booking-form desktop">
                    <h2>{{$t('vehicle.booking_details')}}</h2>

                    <div class="form-container">
                        <input v-model="$v.booking.pick_up_location.$model" :placeholder="$t('vehicle.pick_up_location')" :class="{'error': $v.booking.pick_up_location.$error}"/>
                        <VueCtkDateTimePicker color="#171628" v-model="$v.booking.pick_up_date.$model" :min-date="todayDate" :label="$t('vehicle.pick_up_date')" :minute-interval="60" id="pick-up-date" :class="{'error': $v.booking.pick_up_date.$error}"/>
                    </div>

                    <div class="form-container">
                        <input v-model="$v.booking.drop_off_location.$model" :placeholder="$t('vehicle.drop_off_location')" :class="{'error': $v.booking.drop_off_location.$error}"/>
                        <VueCtkDateTimePicker color="#171628" v-model="$v.booking.drop_off_date.$model" :min-date="minReturnDate" :max-date="maxReturnDate" :minute-interval="60" :label="$t('vehicle.drop_off_date')" id="return-date" :class="{'error': $v.booking.drop_off_date.$error}"/>
                    </div>

                    <div class="form-container">
                        <input v-model="$v.booking.obo.$model" :placeholder="$t('vehicle.obo')" :class="{'error': $v.booking.obo.$error}"/>
                    </div>

                    <button class="button --primary" @click="submit" :disabled="is_submitting" :class="{'spinner-white': is_submitting}">{{$t('our_fleet.book_now')}}</button>
                </div>
            </div>

            <div class="row-reverse">
                <div class="booking-form mobile">
                    <h2>{{$t('vehicle.booking_details')}}</h2>

                    <div class="form-container">
                        <input v-model="$v.booking.pick_up_location.$model" :placeholder="$t('vehicle.pick_up_location')" :class="{'error': $v.booking.pick_up_location.$error}"/>
                        <VueCtkDateTimePicker color="#171628" v-model="$v.booking.pick_up_date.$model" :min-date="todayDate" :label="$t('vehicle.pick_up_date')" id="pick-up-date" :class="{'error': $v.booking.pick_up_date.$error}"/>
                    </div>

                    <div class="form-container">
                        <input v-model="$v.booking.drop_off_location.$model" :placeholder="$t('vehicle.drop_off_location')" :class="{'error': $v.booking.drop_off_location.$error}"/>
                        <VueCtkDateTimePicker color="#171628" v-model="$v.booking.drop_off_date.$model" :min-date="minReturnDate" :label="$t('vehicle.drop_off_date')" id="return-date" :class="{'error': $v.booking.drop_off_date.$error}"/>
                    </div>

                    <div class="form-container">
                        <input v-model="$v.booking.obo.$model" :placeholder="$t('vehicle.obo')" :class="{'error': $v.booking.obo.$error}"/>
                    </div>

                    <button class="button --primary" @click="submit" :disabled="is_submitting">{{$t('our_fleet.book_now')}}</button>
                </div>

                <div class="images-container" v-if="vehicle.relationships.documents && vehicle.relationships.documents.data">
                    <img :src="image.attributes.image_path" v-for="image in vehicle.relationships.documents.data">
                </div>
            </div>

            <div class="features-container">
                <div class="feature-wrapper">
                    <div class="feature">
                        <h3 class="feature-title">{{vehicle.attributes.seats}} Seater</h3>
                        <div class="feature-content">
                            <img class="feature-icon" src="../../src/assets/icons/person-seat-reclined-light.svg"/>
                            <p class="feature-text">{{$t('vehicle.seating_capacity')}}</p>
                        </div>
                    </div>
                </div>

                <div class="feature-wrapper">
                    <div class="feature">
                        <h3 class="feature-title">{{vehicle.attributes.transmission}}</h3>
                        <div class="feature-content">
                            <img class="feature-icon" src="../../src/assets/icons/gear-shift-light.svg"/>
                            <p class="feature-text">{{$t('vehicle.transmission')}}</p>
                        </div>
                    </div>
                </div>

                <div class="feature-wrapper">
                    <div class="feature">
                        <h3 class="feature-title">{{vehicle.attributes.fuel_type}}</h3>
                        <div class="feature-content">
                            <font-awesome-icon class="feature-icon" :icon="['fal','gas-pump']"/>
                            <p class="feature-text">{{$t('vehicle.fuel_type')}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <p class="description" v-if="vehicle.attributes.description">{{vehicle.attributes.description}}</p>
        </div>
    </div>
</template>

<script>
import LoadingScreen from "@/components/LoadingScreen";
import moment from "moment";
import FormInputText from "@/components/form/FormInputText";
import {required} from "vuelidate/lib/validators";

export default {
    name: "VehicleInner",
    components: {FormInputText, LoadingScreen},
    data() {
        return {
            vehicle: null,
            booking: {
                pick_up_location: null,
                pick_up_date: null,
                drop_off_location: null,
                drop_off_date: null,
                obo: null,
            },
            images: ['front.jpg', 'front.jpg', 'front.jpg'],
            is_loading_vehicle: false,
            is_submitting: false
        }
    },
    validations: {
        booking: {
            pick_up_location: {required},
            pick_up_date: {required},
            drop_off_location: {required},
            drop_off_date: {required},
            obo: {},
        }
    },
    computed: {
        todayDate() {
            let date = new Date();
            return moment(date).format('YYYY-MM-DD HH:mm');
        },
        minReturnDate() {
            return moment(this.booking.pick_up_date).add(1, 'hours').format('YYYY-MM-DD HH:mm');
        },
        maxReturnDate() {
            return moment(this.booking.pick_up_date).endOf('day').format('YYYY-MM-DD HH:mm');
        }
    },
    methods: {
        getVehicle() {
            this.is_loading_vehicle = true;

            this.$axios.get(`vehicles/${this.$route.params.vehicle_id}`, {params: this.serverParams})
                .then(({data}) => {
                    this.vehicle = data.data;
                    this.is_loading_vehicle = false;
                })
                .catch(e => {
                    this.is_loading_vehicle = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('our_fleet.error_retrieve_vehicles')),
                        type: 'error',
                    });
                });
        },
        submit() {
            this.$v.booking.$touch();
            if(this.$v.booking.$anyError) return;

            this.is_submitting = true;

            let payload = {
                pick_up_location: this.booking.pick_up_location,
                drop_off_location: this.booking.drop_off_location,
                from: moment(this.booking.pick_up_date).format('YYYY-MM-DD HH:mm'),
                to: moment(this.booking.drop_off_date).format('YYYY-MM-DD HH:mm'),
                status: 'pending',
                vehicle_id: this.$route.params.vehicle_id,
                user_id: this.$store.getters.user.id,
                obo: this.booking.obo,
            }

            this.$axios.post(`vehicles/${this.$route.params.vehicle_id}/book`, payload).then(({data}) => {
                this.is_submitting = false;
                this.$router.push({name: 'bookings'});
            }).catch(e => {
                this.is_submitting = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.message, this.$t('vehicle.failed_create_booking')),
                    type: 'error',
                });
            });
        }
    },
    mounted() {
        this.getVehicle();
    }
}
</script>

<style lang="scss" scoped>
    .inner-page-container {
        @apply bg-grey flex flex-col px-4;

        @screen md {
            @apply px-6;
        }

        .content {
            @apply flex flex-col w-full max-w-9xl mx-auto;

            @screen 2xl {
                @apply px-6;
            }

            .heading-bar {
                @apply flex flex-row my-12 items-center;

                & > h1 {
                    @apply text-3xl font-semibold mr-8;

                    @screen md {
                        @apply text-4xl;
                    }

                    @screen lg {
                        @apply text-5xl;
                    }
                }

                & > .vehicle-license-plate {
                    @apply text-lg px-2 py-1 border-2 border-primary-dimmed rounded font-semibold;
                }
            }

            .main-img {
                @apply bg-white rounded-2xl p-4 mb-4 w-full;

                @screen md {
                    @apply mb-6;
                }

                @screen lg {
                    @apply max-w-2xl mr-2 mb-8;
                }

                @screen xl {
                    @apply max-w-4xl;
                }

                @screen 2xl {
                    @apply max-w-5xl;
                }

                img {
                    @apply max-w-2xl w-full mx-auto;
                }
            }

            .row {
                @apply flex flex-col;

                @screen md {
                    @apply flex flex-row;
                }
            }

            .row-reverse {
                @apply flex flex-col;

                @screen md {
                    @apply flex flex-row-reverse;
                }

                @screen lg {
                    @apply flex-row;
                }
            }

            .booking-form {
                @apply mb-12 rounded-2xl bg-white py-8 px-6;

                @screen md {
                    @apply w-2/5 ml-2;
                }

                @screen lg {
                    @apply flex-1 mb-8;
                }

                &.mobile {
                    @apply block;

                    @screen lg {
                        @apply hidden;
                    }
                }

                &.desktop {
                    @apply hidden;

                    @screen lg {
                        @apply block;
                    }
                }

                & > h2 {
                    @apply text-2xl font-semibold text-center mb-8;

                    @screen md {
                        @apply text-3xl;
                    }

                    @screen lg {
                        @apply text-4xl;
                    }
                }

                .form-container {
                    @apply mb-6 pb-6 border-b-2 border-primary;
                    &:last-of-type {
                        @apply mb-0 pb-0 border-b-0 border-primary;
                    }

                    input {
                        @apply mb-4 text-sm border border-primary-dimmed w-full rounded p-2 px-3;

                        @screen xl {
                            @apply text-base;
                        }

                        &::placeholder {
                            @apply text-secondary;
                        }

                        &.error {
                            @apply border-negative;
                        }
                    }
                }

                button {
                    @apply w-full mt-6 rounded-xl;
                }
            }

            .images-container {
                @apply flex flex-row flex-wrap -m-2;

                @screen md {
                    @apply w-3/5 mr-2;
                }

                @screen lg {
                    @apply w-full;
                    max-width: 43rem;
                }

                @screen xl {
                    max-width: 57rem;
                }

                @screen 2xl {
                    max-width: 65rem;
                }

                img {
                    @apply w-1/2 rounded-2xl p-2 h-48;
                    object-fit: cover;

                    @screen md {
                        @apply h-1/2;
                    }

                    @screen lg {
                        @apply h-52 w-1/3;
                    }
                }
            }

            .features-container {
                @apply flex flex-row flex-wrap my-8 -mx-2 w-auto;

                @screen md {
                    @apply max-w-xl;
                }

                @screen lg {
                    @apply mt-6;
                }

                .feature-wrapper {
                    @apply w-1/2 p-2;

                    @screen md {
                        @apply w-1/3;
                    }

                    .feature {
                        @apply rounded-xl bg-white py-4 px-1;

                        .feature-title {
                            @apply text-center text-lg font-semibold text-primary mb-2;
                        }

                        .feature-content {
                            @apply flex flex-row w-full items-center;

                            .feature-text {
                                @apply text-sm mr-auto;

                                @screen lg {
                                    @apply text-base;
                                }
                            }

                            .feature-icon {
                                @apply ml-auto mr-2 text-lg;
                            }
                        }
                    }
                }
            }

            .description {
                @apply text-15 mb-8;

                @screen lg {
                    @apply max-w-2xl;
                }

                @screen xl {
                    @apply max-w-4xl;
                }

                @screen 2xl {
                    @apply max-w-5xl;
                }
            }
        }
    }
</style>