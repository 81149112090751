<template>
    <nav class="menubar-container">
        <div class="content">
            <img src="../assets/logo-white.svg" class="logo" @click="$router.push({name: 'home'})">

            <button class="btn-mobile-menu" @click="$store.commit('TOGGLE_MENU_EXPANDED')">
                <font-awesome-icon :icon="['fal', 'bars']"/>
            </button>

            <nav>
                <ul class="menu">
                    <li>
                        <router-link :to="{name: 'our-fleet'}">{{ $t('nav.our_fleet') }}</router-link>
                    </li>
                </ul>
            </nav>
        </div>
    </nav>
</template>

<script>
import Avatar from 'vue-avatar'
import UserIcon from './UserIcon'

export default {
    name: "AuthMenubar",
    components: {UserIcon, Avatar},
    methods: {
        async logout() {
            await this.$store.dispatch('logout');
            this.$router.push({name: 'login'});
        },
    }
}
</script>

<style lang="scss" scoped>
.menubar-container {
    @apply bg-secondary w-full;
    grid-area: menubar;

    .content {
        @apply flex flex-row justify-between h-20 text-white max-w-9xl mx-auto;

        @screen xl {
            @apply h-24;
        }
    }

    .btn-mobile-menu {
        @apply mr-6;

        @screen md {
            @apply hidden;
        }

        svg {
            @apply text-white text-xl;
        }

        &:active,&:focus{
            @apply outline-none;
        }
    }

    img.logo{
        @apply h-12 my-auto w-auto ml-6 cursor-pointer;

        @screen lg {
            @apply h-14;
        }

        @screen 2xl {
            @apply ml-0;
        }
    }

    nav {
        @apply my-auto hidden;

        @screen md {
            @apply block;
        }

        .menu {
            @apply flex flex-row p-0 list-none;

            li {
                a {
                    @apply flex text-white cursor-pointer py-4 px-6 text-sm font-bold;
                }
            }
        }
    }
}
</style>