<template>
    <div class="section-header-container">
        <h2>{{title}}</h2>
    </div>
</template>

<script>
    export default {
        name: "SectionHeader",
        props: {
            title: {
                type: String,
                required: true,
            }
        }
    }
</script>

<style lang="scss" scoped>
    .section-header-container {
        @apply bg-grey-light text-black py-3 px-8 rounded-t w-full;

        h2 {
            @apply text-black text-lg font-bold text-left;
        }
    }
</style>