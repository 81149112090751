<template>
  <div class="input-group" :class="{ used, error: hasError, reserveErrorSpace }">
    <label v-if="label" :for="identifier">{{ label }}</label>
    <div class="input-container">
      <input
        v-if="mask"
        :id="identifier"
        :value="value"
        @input="(e) => $emit('input', e.target.value)"
        :type="show_password ? 'text' : 'password'"
        @change="(e) => $emit('change')"
        :disabled="disabled"
        :autocomplete="autocomplete"
        class="input"
        :readonly="readonly"
        :autofocus="autofocus"
        spellcheck="false"
        :maxlength="maxlength"
        v-mask="mask"
      />
      <input
        v-else
        :id="identifier"
        :value="value"
        @input="(e) => $emit('input', e.target.value)"
        :type="show_password ? 'text' : 'password'"
        @change="(e) => $emit('change')"
        :disabled="disabled"
        :autocomplete="autocomplete"
        class="input"
        :readonly="readonly"
        :autofocus="autofocus"
        spellcheck="false"
        :maxlength="maxlength"
      />
      <button type="button" @click="generate" v-if="generator" tabindex="-1">
        <font-awesome-icon :icon="['far', 'sync']" />
      </button>
      <button
        type="button"
        @click="show_password = false"
        v-if="show_password"
        tabindex="-1"
      >
        <font-awesome-icon :icon="['far', 'eye-slash']" />
      </button>
      <button type="button" @click="show_password = true" v-else tabindex="-1">
        <font-awesome-icon :icon="['far', 'eye']" />
      </button>
    </div>
    <FormErrors v-if="hasError" :reserveErrorSpace="reserveErrorSpace">
      <slot name="errors"></slot>
    </FormErrors>
    <div v-else-if="reserveErrorSpace" class="error-space"></div>
  </div>
</template>

<script>
import FormErrors from './FormErrors';

export default {
  name: 'FormInputPassword',
  components: { FormErrors },
  props: {
    value: {
      type: String,
    },
    identifier: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      required: false,
    },
    generator: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: null,
    },
    reserveErrorSpace: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show_password: false,
    };
  },
  methods: {
    generate() {
      const generated = Math.random().toString(36).slice(-8);
      this.$emit('input', generated);
    },
  },
  computed: {
    used() {
      return this.value !== null && this.value.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-group {
    @apply w-full flex flex-col items-start mb-5;

    label {
        @apply text-sm text-black font-bold mb-3;
    }

  .input-container {
    @apply flex flex-row items-center bg-white border-2 border-grey-light rounded w-full;

    input {
        @apply bg-white text-black text-sm font-normal rounded w-full py-3 px-5 appearance-none;


        &:focus {
        @apply outline-none;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-text-fill-color: theme('colors.black');
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
      }

      &:disabled {
        @apply text-grey;
      }
    }

    button {
      @apply p-0 ml-4;

      &:last-of-type {
        @apply mr-4;
      }

      &:active,
      &:focus {
        @apply outline-none;
      }

      svg {
        @apply cursor-pointer text-grey-dark text-sm;
      }
    }

    &:focus-within {
      @apply border-primary;
    }
  }

  &.error {
    .input-container {
      @apply border-negative;
    }
  }

  &:focus-within,
  &.used {
    label {
      @apply bg-white;
    }
  }

  &.reserveErrorSpace {
    @apply mb-0;
  }
}
</style>
