<template>
    <div class="mobile-menu-container">
        <nav>
            <ul class="menu">
                <li>
                    <router-link :to="{name: 'our-fleet'}">{{ $t('nav.our_fleet') }}</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'bookings'}">{{ $t('nav.my_bookings') }}</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'profile'}">{{ $t('nav.my_profile') }}</router-link>
                </li>
                <li class="logout" @click="logout">
                    <p>Log Out</p>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    name: "MobileMenu",
    methods: {
        async logout() {
            await this.$store.dispatch('logout');
            this.$router.push({name: 'login'});
        },
    }
}
</script>

<style lang="scss" scoped>
.mobile-menu-container {
    @apply flex-1 h-auto p-8 bg-white;

    @screen md {
        @apply hidden;
    }

    nav {
        @apply mx-auto w-full;

        .menu {
            @apply flex flex-col p-0 list-none mx-auto;

            li {
                @apply mx-auto;

                a {
                    @apply flex text-black cursor-pointer py-8 text-lg font-bold text-center;
                }

                &.logout {
                    @apply bg-primary rounded h-8 mt-8 px-4 text-lg text-white text-center m-auto flex;

                    @screen xl {
                        @apply mr-0;
                    }

                    &:hover {
                        @apply cursor-pointer bg-primary-over;
                    }

                    & > p {
                        @apply my-auto;
                    }
                }
            }
        }
    }
}
</style>