<template>
    <section>
        <div class="spinner-large"></div>
    </section>
</template>

<script>
    export default {
        name: "loading-screen"
    }
</script>

<style lang="scss" scoped>
    section {
        @apply w-full h-full flex flex-col justify-center items-center bg-grey;
        min-height: 500px;
    }
</style>
