<template>
    <div class="inner-page-container">
        <div class="banner-container">
            <div class="content">
                <div class="text-container">
                    <h1 class="banner-title">{{$t('home.title')}}</h1>
                    <div class="banner-subtitle">{{$t('home.subtitle')}}</div>
                </div>

                <img src="../../src/assets/car-img.png">
            </div>
        </div>

        <div class="searchbar-container">
            <div class="date-picker-container">
                <font-awesome-icon :icon="['fal','calendar']"/>
                <VueCtkDateTimePicker color="#171628" v-model="pick_up_date" :min-date="todayDate" :minute-interval="60" :label="$t('home.pick_up_date')" id="pick-up-date"/>
            </div>
            <div class="date-picker-container">
                <font-awesome-icon :icon="['fal','calendar']"/>
                <VueCtkDateTimePicker color="#171628" v-model="return_date" :min-date="minReturnDate" :max-date="maxReturnDate" :minute-interval="60" :label="$t('home.return_date')" id="return-date"/>
            </div>
            <button class="button --primary" @click="search">Search</button>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: "Home",
    data() {
        return {
            pick_up_date: null,
            return_date: null
        }
    },
    computed: {
        todayDate() {
            let date = new Date();
            return moment(date).format('YYYY-MM-DD HH:mm');
        },
        minReturnDate() {
            return moment(this.pick_up_date).add(1, 'hours').format('YYYY-MM-DD HH:mm');
        },
        maxReturnDate() {
            return moment(this.pick_up_date).endOf('day').format('YYYY-MM-DD HH:mm');
        }
    },
    methods: {
        search() {
            if(!this.pick_up_date) {
                this.$notify({
                    title: this.$t('error'),
                    text: this.$t('home.pick_up_required'),
                    type: 'error',
                });
                return;
            } else if(!this.return_date) {
                this.$notify({
                    title: this.$t('error'),
                    text: this.$t('home.return_required'),
                    type: 'error',
                });
                return;
            }

            this.$router.push({name: 'our-fleet', query: {pick_up_date: this.pick_up_date, return_date: this.return_date}})
        }
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.home')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
    .inner-page-container {
        @apply bg-grey-light;

        @screen xl {
            @apply bg-grey;
        }

        .banner-container {
            @apply w-full bg-grey-banner;

            .content {
                @apply flex flex-col py-8 w-full px-4 max-w-9xl mx-auto;
                height: min-content;

                @screen md {
                    @apply flex-row py-16 px-6;
                }

                @screen lg {
                    @apply px-8 pb-32;
                }

                @screen xl {
                    @apply px-24;
                }

                .text-container {
                    @apply mb-8;

                    @screen sm {
                        @apply max-w-none;
                    }

                    @screen md {
                        @apply w-2/5 mr-8 my-auto;
                    }

                    @screen lg {
                        @apply mr-12;
                    }

                    .banner-title {
                        @apply font-bold text-4xl mb-6;

                        @screen md {
                            @apply max-w-2xs;
                        }

                        @screen lg {
                            @apply text-5xl max-w-md;
                        }
                    }

                    .banner-subtitle {
                        @apply text-sm;

                        @screen lg {
                            @apply text-2xl max-w-lg;
                        }
                    }
                }

                img {
                    @apply max-w-sm mx-auto;

                    @screen md {
                        @apply w-3/5 ml-auto max-w-none;
                    }

                    @screen lg {
                        @apply max-w-3xl;
                    }
                }
            }
        }

        .searchbar-container {
            @apply flex flex-col bg-white p-6 rounded-2xl mx-auto max-w-sm w-full -mt-4 mb-8;
            box-shadow: 0px 5px 25px #00000029;

            @screen lg {
                @apply max-w-4xl w-full flex-row justify-between -mt-12;
            }

            .date-picker-container {
                @apply mb-4 flex flex-row;

                @screen lg {
                    @apply my-auto mx-auto;
                }

                &:first-of-type {
                    @screen lg {
                        @apply pr-4 mr-8 border-r-2 border-primary;
                    }
                }

                svg {
                    @apply my-auto text-xl mr-4;

                    @screen lg {
                        @apply text-2xl;
                    }
                }
            }

            button {
                @apply w-full rounded-lg;

                @screen md {
                    @apply w-auto;
                }
            }
        }
    }
</style>