<template>
    <div class="inner-page-container">
        <div class="content">
            <div class="heading-bar">
                <h1>{{ $t('nav.my_bookings') }}</h1>
            </div>

            <vue-good-table
                mode="remote"
                styleClass="vgt-custom vgt-table"
                :columns="columns"
                :rows="bookings"
                :isLoading.sync="is_loading_bookings"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('bookings.bookings')}),
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'attributes.from'">
                        {{$moment(props.row.attributes.from).format("DD/MM/YYYY - hh:mm A")}}
                    </div>
                    <div v-else-if="props.column.field === 'attributes.to'">
                        {{$moment(props.row.attributes.to).format("DD/MM/YYYY - hh:mm A")}}
                    </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <button class="icon" v-if="props.row.attributes.status === 'pending'" @click="toggleStart(props.row)">
                            <font-awesome-icon :icon="['fal', 'play']"/>
                        </button>
                        <button class="icon" v-else-if="props.row.attributes.status === 'in_progress'" @click="toggleStop(props.row)">
                            <font-awesome-icon :icon="['fal', 'stop']"/>
                        </button>
                        <button class="delete-icon" v-if="props.row.attributes.status !== 'completed'" @click="toggleDelete(props.row)" :disabled="props.row.attributes.status === 'in_progress'">
                            <font-awesome-icon :icon="['fal', 'minus-circle']"/>
                        </button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </div>
    </div>
</template>

<script>
import ConfirmModal from "@/components/modal/ConfirmModal";
import moment from "moment";
import StartBookingModal from "@/components/modal/StartBookingModal";
import StopBookingModal from "@/components/modal/StopBookingModal";

export default {
    name: "MyBookings",
    data() {
        return {
            columns: [
                {
                    label: this.$t('bookings.id'),
                    field: 'attributes.reference',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.date_time_from'),
                    field: 'attributes.from',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.date_time_to'),
                    field: 'attributes.to',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.obo'),
                    field: 'attributes.obo',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.license_plate'),
                    field: 'relationships.vehicle.data.attributes.license_plate',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.model'),
                    field: 'relationships.vehicle.data.attributes.model',
                    sortable: false,
                },
                {
                    label: this.$t('bookings.kilometers'),
                    field: this.kilometersFn,
                    sortable: false,
                },
                {
                    label: this.$t('actions'),
                    field: 'after',
                    tdClass: 'td-after',
                    sortable: false
                }
            ],
            bookings: [],
            serverParams: {},
            totalRecords: null,
            is_loading_bookings: false
        }
    },
    methods: {
        kilometersFn(row) {
            if(row.attributes.trip_finish_km && row.attributes.trip_start_km)
                return (parseInt(row.attributes.trip_finish_km) - parseInt(row.attributes.trip_start_km)).toLocaleString('en');
            else
                return 'N/A';
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.getBookings();
        },
        toggleStart(booking) {
            this.$modal.show(
                StartBookingModal, {
                    title: `${this.$t('bookings.start_booking')} - ${booking.attributes.reference}`,
                    message: this.$t('bookings.please_enter_kilometers'),
                    confirmText: this.$t('start'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline',
                    booking: booking
                },
                {
                    name: 'start-booking-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.getBookings();
                        }
                    }
                }
            );
        },
        toggleStop(booking) {
            this.$modal.show(
                StopBookingModal, {
                    title: `${this.$t('bookings.stop_booking')} - ${booking.attributes.reference}`,
                    message: this.$t('bookings.please_enter_kilometers'),
                    confirmText: this.$t('stop'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline',
                    booking: booking
                },
                {
                    name: 'stop-booking-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.getBookings();
                        }
                    }
                }
            );
        },
        toggleDelete(booking) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('bookings.cancel_booking'),
                    message: this.$t('bookings.prompt_cancel'),
                    confirmText: this.$t('confirm'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`bookings/${booking.id}`)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.getBookings();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('booking.error_cancel')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        getBookings() {
            this.is_loading_bookings = true;

            this.$axios.get('profile/bookings', {params: this.serverParams})
                .then(({data}) => {
                    this.bookings = data.data;
                    this.totalRecords = data.meta.total;
                    this.is_loading_bookings = false;
                })
                .catch(e => {
                    this.is_loading_bookings = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },
    mounted() {
        this.getBookings();
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.my_bookings')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
    .inner-page-container {
        @apply bg-grey flex flex-col px-4;

        @screen md {
            @apply px-6;
        }

        .content {
            @apply flex flex-col w-full max-w-9xl mx-auto;

            @screen 2xl {
                @apply px-6;
            }

            .heading-bar {
                @apply flex flex-row my-12 items-center;

                & > h1 {
                    @apply text-3xl font-semibold mr-8;

                    @screen md {
                        @apply text-4xl;
                    }

                    @screen lg {
                        @apply text-5xl;
                    }
                }
            }

            .td-after {
                @apply flex;

                .icon {
                    @apply mx-auto;

                    & > svg {
                        @apply border-2 border-grey p-1 rounded text-lg;
                    }
                }

                .delete-icon {
                    @apply mx-auto;

                    & > svg {
                        @apply border-2 border-grey p-1 rounded text-negative text-lg;
                    }

                    &:disabled {
                        @apply cursor-not-allowed;

                        & > svg {
                            @apply border-grey-lighter text-negative-over;
                        }
                    }
                }
            }
        }
    }
</style>