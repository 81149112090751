<template>
    <nav class="menubar-container">
        <div class="content">
            <img src="../assets/logo.svg" class="logo" @click="$router.push({name: 'home'})">

            <button class="btn-mobile-menu" @click="$store.commit('TOGGLE_MENU_EXPANDED')">
                <font-awesome-icon :icon="['fal', 'bars']"/>
            </button>

            <nav>
                <ul class="menu">
                    <li>
                        <router-link :to="{name: 'our-fleet'}">{{ $t('nav.our_fleet') }}</router-link>
                    </li>
                    <li v-if="$store.getters.user">
                        <router-link :to="{name: 'bookings'}">{{ $t('nav.my_bookings') }}</router-link>
                    </li>
                    <li v-if="$store.getters.user">
                        <router-link :to="{name: 'profile'}">{{ $t('nav.my_profile') }}</router-link>
                    </li>
                    <li v-if="$store.getters.user" class="auth-button" @click="logout">
                        <p>Log Out</p>
                    </li>
                    <li v-if="!$store.getters.user" class="auth-button" @click="login">
                        <p>Log In</p>
                    </li>
                </ul>
            </nav>
        </div>
    </nav>
</template>

<script>
import Avatar from 'vue-avatar'
import UserIcon from './UserIcon'

export default {
    name: "Menubar",
    components: {UserIcon, Avatar},
    methods: {
        async logout() {
            await this.$store.dispatch('logout');
            this.$router.push({name: 'login'});
        },
        async login() {
            this.$router.push({name: 'login'});
        },
    }
}
</script>

<style lang="scss" scoped>
.menubar-container {
    @apply bg-white w-full;
    grid-area: menubar;

    .content {
        @apply flex flex-row justify-between h-20 text-black max-w-9xl mx-auto;

        @screen xl {
            @apply h-24;
        }
    }

    .btn-mobile-menu {
        @apply mr-4;

        @screen md {
            @apply hidden;
        }

        svg {
            @apply text-black text-xl;
        }

        &:active,&:focus{
            @apply outline-none;
        }
    }

    img.logo{
        @apply h-12 my-auto w-auto ml-4 cursor-pointer;

        @screen md {
            @apply ml-6;
        }

        @screen lg {
            @apply h-14;
        }

    }

    nav {
        @apply my-auto hidden;

        @screen md {
            @apply block;
        }

        .menu {
            @apply flex flex-row p-0 list-none;

            li {
                a {
                    @apply flex text-black cursor-pointer py-4 mx-6 text-sm font-bold;

                    &.router-link-active {
                        @apply pb-2 border-b-2 border-primary-over;
                    }
                }

                &.auth-button {
                    @apply bg-primary rounded h-8 px-4 text-white text-center my-auto text-sm mx-6 flex;

                    &:hover {
                        @apply cursor-pointer bg-primary-over;
                    }

                    & > p {
                        @apply my-auto font-semibold;
                    }
                }
            }

            button {
                @apply ml-6;
            }
        }
    }
}
</style>